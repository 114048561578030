import { monthNames } from '@/enums/monthNames'
import { computed } from "vue"
import { useRoute } from "vue-router"

export const utils = {
	formatNumber(number) {
		if(!isNaN(number) && number > 0){
			return new Intl.NumberFormat().format(number);
		}else{
			return '-'
		}
	},
	generateMonth(from, count) {
		if(monthNames){
			let startIndex = monthNames.findIndex(e => e === from[0]);
			let year = parseInt(from[1]);

			let labelsArr = [];

			for (var i = 0; i <= count - 1; i++) {
				labelsArr.push(monthNames[startIndex] + ' ' + year);

				startIndex++
				if(startIndex === 12){
					startIndex = 0;
					year = year + 1;
				}
			}
			return labelsArr;
		}
	},
	useIsParentActive(parentRouteName){
		// v-if only
		const route = useRoute();
		return route.matched.some((record) => record.name === parentRouteName);
	}
};