import { defineStore } from 'pinia'
import { $api, mainURL } from '@/api_client'

export const useAuthStore = defineStore('auth', {
	state: () => ({
		access_token: '',
		locale: 'en',
		role: '',
		navs: '',
		user: {},
		guest_token: ''
	}),
	persist: true,

	getters: {
		isAuth (state) {
			return state.access_token !== ''
		}
	},

	actions: {
		login (data) {
			return new Promise((resolve, reject) => {
				$api.post('/api/user/token/', data)
					.then((resp) => {
						this.access_token = resp.access_token
						this.role = resp.role
						this.user = resp.user
						this.navs = resp.navs
						resolve(resp)
					})
					.catch((error_data) => {
						reject(error_data)
					})
			})
		},
		logout () {
			this.$reset()
		},
		checkRole(roles){
			return roles.some(item => this.role.includes(item));
		}
	}
})
