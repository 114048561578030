import { defineStore } from 'pinia';
import { $api } from '@/api_client';

export const useCQ = defineStore('ClientQuote', {
	state: () => ({
		products: [],
		activeProductIndex: null,
		currentStep: 0,
		filters: {
			country_name: [],
			industry_name: [],
			n_of_employees_group: [],
			revenue_group: [],
			job_function_name: [],
			job_level_group: [],
		},
		selected: {
			country_name: [],
			industry_name: [],
			n_of_employees_group: [],
			revenue_group: [],
			job_function_name: [],
			job_level_group: [
				'C-Level',
				'Directors',
				'Managers',
				'Vice Presidents',
			],
		},
		headers: {
			selected_logo:{
				show: false,
				value: {}
			}
		},
		cart: [],
		currency: '$',
		totalCost: 0,
		totalLeads: 0,
		totalLeadsByType: {},
		reportSetup: null,
		linkPart: '',
		report_title: '',
		client_comment: '',
	}),
	actions: {
		async getProducts() {
			this.products = [];
			const result = await $api.get('/api/get-account-price-list/');
			this.products.push(...result);
		},
		saveSelectedProduct(product, index) {
			this.selectedProduct = product;
			this.activeProductIndex = index;
		},
		setCurrentStep(step) {
			this.currentStep = step;
		},
		setFilters(key, data) {
			this.filters[key] = data;
		},
		addSelected(data) {
			this.selected = data;
		},
		updateFilter(key, values) {
			this.selected[key] = values;
		},
		resetFilters() {
			this.selected = {
				country_name: [],
				industry_name: [],
				n_of_employees_group: [],
				revenue_group: [],
				job_function_name: [],
				job_level_group: [],
			};
		},
		setCurrency(data) {
			this.currency = data;
		},
		calculateTotals() {
			this.totalCost = this.cart.reduce((sum, product) => {
				return sum + (parseInt(product.cost_of_product) || 0);
			}, 0);

			this.totalLeadsByType = this.cart.reduce((acc, product) => {
				const unitType = product.unit_type || 'Unknown';
				acc[unitType] =
					(acc[unitType] || 0) + (parseInt(product.volume) || 0);
				return acc;
			}, {});

			this.totalLeads = Object.values(this.totalLeadsByType).reduce(
				(sum, val) => sum + val,
				0,
			);
		},
		getAllFilterItems(key) {
			const extractItems = (items) => {
				let result = [];
				for (const item of items) {
					if (item.items && item.items.length > 0) {
						result = result.concat(extractItems(item.items));
					} else {
						result.push(item.name);
					}
				}
				return result;
			};

			return extractItems(this.filters[key]);
		},
		addToCart(product) {
			const audience = {};
			const filterLabels = {
				country_name: 'Countries',
				industry_name: 'Industry Verticals',
				n_of_employees_group: 'Company Size',
				revenue_group: 'Company Revenue',
				job_function_name: 'Job Functions',
				job_level_group: 'Job Level',
			};

			Object.keys(this.selected).forEach((key) => {
				const allPossibleItems = this.getAllFilterItems(key);
				const isAllSelected =
					allPossibleItems.length > 0 &&
					this.selected[key].length === allPossibleItems.length &&
					this.selected[key].every((item) =>
						allPossibleItems.includes(item),
					);
			
				const isNoneSelected = this.selected[key].length === 0;
			
				audience[key] = isNoneSelected
					? `All ${filterLabels[key]} are not selected`
					: isAllSelected
					? `All ${filterLabels[key]} Selected`
					: this.selected[key];
			});

			this.cart.push({
				...product,
				audience,
			});

			// Reset selected filrers
			// this.resetFilters();
		},
		resetStore() {
            this.cart = [];
            this.currency = null;
        },
	},
});
