// import { h, resolveComponent } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import { useAuthStore } from '@/store/auth'
import { useCQ } from '@/store/clientQuote'

function checkAccess(allowedRoles) {
	return (to, from, next) => {
		const authStore = useAuthStore();
		if (!authStore.checkRole(allowedRoles)) {
			next({ name: 'HourGlass' });
		} else {
			next();
		}
	};
}

const routes = [
	{
		path: '/login',
		name: 'Login',
		component: () => import(/* webpackChunkName: "main" */ '@/pages/Login/'),
		meta: {
			title: 'Live INFUSE Login',
			requiresGuest: true
		}
	},
	{
		path: '/forgot-password',
		name: 'ForgotPassword',
		component: () => import(/* webpackChunkName: "main" */ '@/pages/ForgotPassword/'),
		meta: {
			title: 'Live INFUSE Restore Password',
			requiresGuest: true
		}
	},
	{
		path: '/order-review',
		name: 'OrderReview',
		component: () => import(/* webpackChunkName: "main" */ '@/pages/OrderReview/'),
		meta: {
			title: 'Live INFUSE Order Review',
		}
	},
	{
		path: '/order-secure',
		name: 'OrderSecure',
		component: () => import(/* webpackChunkName: "main" */ '@/pages/OrderSecure/'),
		meta: {
			title: 'Live INFUSE Order Secure',
			// requiresGuest: true
		}
	},
	{
		path: '/',
		name: 'HourGlass',
		component: () => import(/* webpackChunkName: "main" */ '@/pages/HourGlass/'),
		meta: {
			title: 'Live INFUSE Dashboard'
		}
	},
	{
		path: '/mobile',
		name: 'Mobile',
		component: () => import(/* webpackChunkName: "main" */ '@/pages/Mobile/'),
		meta: {
			title: 'Live INFUSE Dashboard Mobile'
		}
	},
	{
		path: '/quotegenerator',
		name: 'Audience',
		component: () => import(/* webpackChunkName: "main" */ '@/pages/Audience/'),
		meta: {
			title: 'Live INFUSE Dashboard Audience'
		},
		children: [
			{
				path: 'audience',
				name: 'Audience',
				component: () => import(/* webpackChunkName: "main" */ '@/pages/Audience/screens/AudienceSelection'),
				meta: {
					title: 'Live INFUSE Dashboard Audience Selection'
				},
				// beforeEnter: checkAccess(['Deal Desk Expert']),
			},
			{
				path: 'programs-setup',
				name: 'ProgramsSetup',
				component: () => import(/* webpackChunkName: "main" */ '@/pages/Audience/screens/ProgramsSetup'),
				meta: {
					title: 'Live INFUSE Dashboard Programs Setup'
				},
				// beforeEnter: checkAccess(['Deal Desk Expert', 'Sales Person']),
			},
			{
				path: 'report',
				name: 'Report',
				component: () => import(/* webpackChunkName: "main" */ '@/pages/Audience/screens/Report'),
				meta: {
					title: 'Live INFUSE Dashboard Report',
					// requiresGuest: true
				},
				// beforeEnter: checkAccess(['Deal Desk Expert', 'Sales Person', 'Guest']),
			},
			{
				path: 'cart',
				name: 'OrderReview',
				component: () => import(/* webpackChunkName: "main" */ '@/pages/OrderReview/'),
				meta: {
					title: 'Live INFUSE Dashboard Order Review',
					// requiresGuest: true
				},
			},
		]
	},
	{
		path: '/guest-deal-desk',
		name: 'GuestAudience',
		component: () => import(/* webpackChunkName: "main" */ '@/pages/Audience/'),
		meta: {
			title: 'Live INFUSE Dashboard Audience'
		},
		beforeEnter: checkAccess(['Guest']),
		children: [
			{
				path: 'report',
				name: 'GuestReport',
				component: () => import(/* webpackChunkName: "main" */ '@/pages/Audience/screens/Report'),
				meta: {
					title: 'Live INFUSE Dashboard Report',
					requiresGuest: true
				}
			},
			{
				path: 'cart',
				name: 'GuestOrderReview',
				component: () => import(/* webpackChunkName: "main" */ '@/pages/OrderReview/'),
				meta: {
					title: 'Live INFUSE Dashboard Order Review',
					requiresGuest: true
				},
			},
			{
				path: '/order-secure',
				name: 'GuestOrderSecure',
				component: () => import(/* webpackChunkName: "main" */ '@/pages/OrderSecure/'),
				meta: {
					title: 'Live INFUSE Order Secure',
					requiresGuest: true
				}
			},
		]
	},
	{
		path: '/quote/:param',
		name: 'QuoteByEmail',
		component: () => import(/* webpackChunkName: "main" */ '@/pages/Audience/screens/QuoteByEmail/'),
		meta: {
			title: 'Live INFUSE Dashboard Quote',
			requiresGuest: true
		},
	},
	// {
	// 	path: '/guest-report',
	// 	name: 'GuestReport',
	// 	component: () => import(/* webpackChunkName: "main" */ '@/pages/Audience/screens/Report'),
	// 	meta: {
	// 		title: 'Live INFUSE Dashboard Report',
	// 		// requiresGuest: true
	// 	},
	// 	// beforeEnter: checkAccess(['Guest']),
	// },
	{
		path: '/client-quote',
		name: 'CQBuilder',
		component: () => import(/* webpackChunkName: "main" */ '@/pages/CQBuilder/'),
		meta: {
			title: 'Live INFUSE Client Quote Builder'
		},
		children: [
			{
				path: 'product-select',
				name: 'ProductSelect',
				component: () => import(/* webpackChunkName: "main" */ '@/pages/CQBuilder/screens/ProductSelect'),
				meta: {
					title: 'Live INFUSE Client Quote Builder Product Setup'
				},
				// beforeEnter: [checkRole],
			},
			{
				path: 'target-flow',
				name: 'TargetFlow',
				component: () => import(/* webpackChunkName: "main" */ '@/pages/CQBuilder/screens/TargetFlow'),
				meta: {
					title: 'Live INFUSE Client Quote Builder Target Flow'
				},
				beforeEnter: (to, from, next) => {
					const cqStore = useCQ();
					if (!cqStore.selectedProduct) {
						next({ name: 'HourGlass' });
					} else {
						next();
					}
				},
			},
			{
				path: 'insertion-order',
				name: 'InsertionOrder',
				component: () => import(/* webpackChunkName: "main" */ '@/pages/CQBuilder/screens/InsertionOrder'),
				meta: {
					title: 'Live INFUSE Client Quote Builder Insertion Order'
				},
				// beforeEnter: [checkRole],
				beforeEnter: (to, from, next) => {
					const cqStore = useCQ();
					if (!cqStore.selectedProduct) {
						next({ name: 'HourGlass' });
					} else {
						next();
					}
				},
			},
			{
				path: 'order-review',
				name: 'CQOrderReview',
				component: () => import(/* webpackChunkName: "main" */ '@/pages/CQBuilder/screens/OrderReview'),
				meta: {
					title: 'Live INFUSE Client Quote Builder Order Review'
				},
				// beforeEnter: [checkRole],
				beforeEnter: (to, from, next) => {
					const cqStore = useCQ();
					if (!cqStore.selectedProduct) {
						next({ name: 'HourGlass' });
					} else {
						next();
					}
				},
			},
			{
				path: 'order-secure',
				name: 'CQOrderSecure',
				component: () => import(/* webpackChunkName: "main" */ '@/pages/CQBuilder/screens/OrderSecure'),
				meta: {
					title: 'Live INFUSE Client Quote Builder Order Secure'
				},
				// beforeEnter: [checkRole],
				beforeEnter: (to, from, next) => {
					const cqStore = useCQ();
					if (!cqStore.selectedProduct) {
						next({ name: 'HourGlass' });
					} else {
						next();
					}
				},
			},
		]
	},
	{
		path: '/launchpad',
		name: 'LaunchPad',
		component: () => import(/* webpackChunkName: "main" */ '@/pages/LaunchPad/'),
		meta: {
			title: 'Live INFUSE LaunchPad'
		},
		children: [
			{
				path: 'setup',
				name: 'Setup',
				component: () => import(/* webpackChunkName: "main" */ '@/pages/LaunchPad/screens/Setup'),
				meta: {
					title: 'Demand Accelerator Live - New LaunchPad Setup'
				},
			},
		],
	},
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
	scrollBehavior() {
		return { top: 0 }
	}
})

router.beforeEach((to, from, next) => {
	const authStore = useAuthStore()
	const token = authStore.access_token
	const title = to.meta.title
	// const guest = authStore.guest_token

	if (title) {
		document.title = title
	}

	if (to.meta.requiresGuest && authStore.isAuth) {
		next({ name: 'HourGlass' })
	} else if (!token && !to.meta.requiresGuest) {
		next({ name: 'Login' })
	} else {
		next()
	}
})

export default router